@use '../../styles/base/variables' as *;

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 0.9rem;
  line-height: 1.4;

  .presentation-container {
    display: block;
    text-align: justify;
    background-color: $violet_echoppeMinos_background;
    padding: 20px;
    overflow: hidden;
    padding-bottom: 60px;

    .client-photo {
      float: left;
      border-radius: 50%;
      margin-right: 20px;
      width: 150px;
      height: 150px;
    }
  }

  @media (max-width: 768px) {
    .presentation-container {
      .client-photo {
        float: none;
        display: block;
        margin: 0 auto 20px;
      }
    }
  }

  .content-choice {
    display: flex;
    flex-direction: row;
    width: 100%;

    .vero-crea-block {
      background-color: $rose_veroCrea_background;

      .base-content {
        h2 {
          margin-top: -75px;
          margin-left: -25px;
          background-color: $rose_veroCrea_background;
          width: 50%;
          padding: 15px;
          border-top-left-radius: 25px;
          border-top-right-radius: 25px;
        }
      }
    }

    .echoppe-des-minos-block {
      background-color: $bleu_base;

      .base-content {
        h2 {
          margin-top: -75px;
          margin-left: -25px;
          background-color: $bleu_base;
          width: 50%;
          padding: 15px;
          border-top-left-radius: 25px;
          border-top-right-radius: 25px;
        }
      }

      .map-content {
        display: flex;
        flex-direction: row;
        gap: 25px;

        @media (max-width: 768px) {
          flex-direction: column;
        }
      }
    }

    .vero-crea-block,
    .echoppe-des-minos-block {
      width: 50%;
      max-width: 50%;
      padding: 25px;
      display: flex;
      flex-direction: column;

      @media (max-width: 768px) {
        width: 100%;
      max-width: 100%;
      }

      .base-content {
        width: 100%;
      }

      .expanded-content {
        width: 0%;
        display: flex;
        flex-direction: column;
      }

      .reseaux-content {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding-top: 25px;
      }
    }
  }
}


@media (max-width: 768px) {
  .content {
    .content-choice {
      flex-direction: column;

      .vero-crea-block,
      .echoppe-des-minos-block {
        width: auto;
      }
    }
  }
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: flex-start;
}

.gallery-item {
  background-color: #f3f3f3;
  width: 30%;
  margin-bottom: 5px;
  cursor: pointer;
  position: relative;
  background-size: cover;
  background-position: center;
}

.gallery-item img {
  max-width: 100%;
  max-height: 100%;
}


.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-image {
  max-width: 80%;
  max-height: 80%;
  box-shadow: 0 0 8px white;
}

.lightbox-close,
.lightbox-prev,
.lightbox-next {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
}

.lightbox-close {
  top: 10px;
  right: 10px;
}

.lightbox-prev {
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.lightbox-next {
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}