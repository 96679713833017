@use '../../styles/base/variables' as *;

.header {
  background-color: $pastel_base;
  padding: 0;
  text-align: center;

  .logo {
    width: 150px;
    height: 150px;
  }

  nav {
    width: 100%;
    display: flex;
    justify-content: space-around;
    list-style: none;
    margin: 0;
    padding: 0;

    ul {
      width: 100%;
      display: flex;
      margin: 0;
      padding: 0;
    }

    li {
      flex: 1;
      text-align: center;
      padding: 15px 0;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;

      a{
        text-decoration: none;
        color: black;
      }
    }

    .a-propos {
      background-color: $violet_echoppeMinos_background;
    }

    .vero-crea {
      background-color: $rose_veroCrea_background;
    }

    .echoppe-des-minos {
      background-color: $bleu_base;
    }
  }
}